body,
div {
  margin: 0;
  padding: 0;
}
body {
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
}

.App {
  // min-height: calc(100vh + 12rem);
  // max-width: 1250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  #description {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 292px;
    height: 242px;
    margin: 3px;
    border: 4px solid white;
    background-color: orange;
    h1 {
      color: white;
      margin: 0;
    }
  }

  .script_div,
  .product_div,
  .placeholder {
    display: flex;
    width: 300px;
    height: 250px;
    margin: 3px;
  }

  @keyframes backgroundColorPending {
    0% {
      background: lightgray;
    }
    100% {
      background: #bdbdbd;
    }
  }

  .placeholder {
    animation-name: backgroundColorPending;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }

  .script_div {
    // display: flex;
    // height: 100%;
    // margin: 3px;
  }

  .product_div {
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // margin: 3px;
    background-color: white;
    text-align: center;
    .product-desc {
      margin-top: 10px;
      width: 270px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      color: royalblue;
    }
    .product-image {
      display: flex;
      img {
        max-height: 160px;
      }
    }
    .amazon-button {
      display: flex;
      margin-top: 10px;
      height: 32px;
      img {
        height: 32px;
      }
    }
  }

  iframe {
    border: none;
  }
}
